import React from "react";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
import Masonry from "react-masonry-css";

import PaginationLinks from "../common/PaginationLinks";
import MediaLink from "./MediaLink";

import * as classes from "./Media.module.scss";

export default function Media({data, pageContext: {humanPageNumber, previousPagePath, nextPagePath}}) {

    const edges = data["allMedia"]["edges"];

    return (
        <section>
            <Helmet>
                <title>Про нас говорят{humanPageNumber > 1 ? `, страница ${humanPageNumber}` : ""}</title>
                <meta name="description" content="О проектах и инициативах компании пишут и говорят в медиапространстве: РБК, ТАСС, Известия, МК и другие." />
            </Helmet>
            <h1>Про нас говорят</h1>
            <Masonry
                className={classes.masonryGrid}
                columnClassName={classes.masonryGridColumn}
                breakpointCols={3}>
                {edges?.map(({node}) =>
                    <MediaLink
                        key={node.id}
                        className={classes.link}
                        {...node} />)}
            </Masonry>
            <PaginationLinks
                previousLinkText="Показать более свежие упоминания"
                nextLinkText="Показать более ранние упоминания"
                previousLinkTo={previousPagePath}
                nextLinkTo={nextPagePath} />
        </section>
    );

}

export const query = graphql`
    query($skip: Int, $limit: Int) {
        allMedia(
            sort: {fields: [timestamp], order: DESC}
            skip: $skip
            limit: $limit
        ) {
            edges {
                node {
                    ...MediaLink
                }
            }
        }
    }
`;
