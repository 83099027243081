import React from "react";
import PropTypes from "prop-types";
import {graphql} from "gatsby";

import MediaLinkTemplate from "../../templates/media/MediaLinkTemplate";

export default function MediaLink({className, title, link, date, timestamp, logo, logoAlt}) {
    return (
        <MediaLinkTemplate
            className={className}
            title={title}
            link={link}
            date={date}
            timestamp={timestamp}
            logo={logo}
            logoAlt={logoAlt} />
    );
}

MediaLink.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    timestamp: PropTypes.string.isRequired,
    logo: PropTypes.object.isRequired,
    logoAlt: PropTypes.string.isRequired
};

export const query = graphql`
    fragment MediaLink on Media {
        id
        title
        link
        date: timestamp(formatString: "DD MMMM YYYY", locale: "ru")
        timestamp
        logo {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 30, placeholder: NONE)
            }            
        }
        logoAlt
    }
`;
