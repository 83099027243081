import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import * as classes from "./SmallCard.module.scss";

export default function SmallCard({className, use: ContainerComponent = "section", ...props}) {
    return (
        <ContainerComponent
            className={classNames(classes.container, className)}
            {...props} />
    );
}

SmallCard.propTypes = {
    className: PropTypes.string,
    use: PropTypes.elementType
};
