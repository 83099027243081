import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import PreviewCompatibleImage from "../../components/helpers/PreviewCompatibleImage";
import SmallCard from "../../components/common/SmallCard";

import * as classes from "./MediaLinkTemplate.module.scss";

export default function MediaLinkTemplate({className, title, link, date, timestamp, logo, logoAlt}) {
    return (
        <SmallCard
            className={classNames(classes.container, className)}
            use="a"
            href={link}
            target="_blank"
            rel="noreferrer">
            <PreviewCompatibleImage
                className={classes.logo}
                image={logo}
                alt={logoAlt} />
            {title}{" "}
            <time className={classes.date} dateTime={timestamp}>{date}</time>
        </SmallCard>
    );
}

MediaLinkTemplate.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    timestamp: PropTypes.string.isRequired,
    logo: PropTypes.object.isRequired,
    logoAlt: PropTypes.string.isRequired
};
