import React from "react";
import PropTypes from "prop-types";

import {useIsMobile} from "../../util/responsiveness";
import ChevronLink from "./ChevronLink";

import * as classes from "./PaginationLinks.module.scss";

export default function PaginationLinks({previousLinkText, nextLinkText, previousLinkTo, nextLinkTo}) {

    const isMobile = useIsMobile();

    if (!previousLinkTo && !nextLinkTo) return null;

    return (
        <div className={classes.container}>
            {previousLinkTo &&
                <ChevronLink
                    className={classes.linkPrevious}
                    chevronSide="LEFT"
                    to={previousLinkTo}>
                    {!isMobile ? previousLinkText : "Более свежие"}
                </ChevronLink>}
            {nextLinkTo &&
                <ChevronLink
                    className={classes.linkNext}
                    chevronSide="RIGHT"
                    to={nextLinkTo}>
                    {!isMobile ? nextLinkText : "Более ранние"}
                </ChevronLink>}
        </div>
    );
}

PaginationLinks.propTypes = {
    previousLinkText: PropTypes.string.isRequired,
    nextLinkText: PropTypes.string.isRequired,
    previousLinkTo: PropTypes.string,
    nextLinkTo: PropTypes.string
};
